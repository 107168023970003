import React, {useEffect, useState} from "react";
import {useLocation, useParams} from "react-router-dom";
import {analyticsBACK, APIClient} from "../../ApiEndpoints";
import {CONCEPT_LIST_META
	// , CONNECTIONS_TYPES
} from "../../graphql/conceptQueries";
import Skeleton from "react-loading-skeleton";
import {STORIES_BY_CONCEPTS_ALL_PLATFORMS, STORIES_BY_CONCEPTS_ALL_PLATFORMS_DATES} from "../../graphql/streamQueries";
import Tooltip from "../../components/ui/Tooltip";
import {HandySvg} from "handy-svg";
import ico_doc from "../../ico/doc.svg";
import Modal from "../../components/ui/Modal";
import {BACK_HOST} from "../../Consts";
import convertSVGtoImg from "../../functions/convertSVGtoImg";
import {useDispatch, useSelector} from "react-redux";
import {setHistory} from "../../reducers/userSlice";
import RememberList from "../../components/search/PememberList";
// import {buildRetryFunction} from "@apollo/client/link/retry/retryFunction";

const ConceptMeta = React.lazy(() => import("../../components/concepts/dossier/ConceptMeta"))
const ConnectionsGraph = React.lazy(() => import("../../components/concepts/connections/ConnectionsGraph"))
const QueryDetailLine = React.lazy(() => import("../../components/ui/reports/QueryDetailLine"))
const ConceptDocs = React.lazy(() => import("../../components/concepts/dossier/ConceptDocs"))
const ConceptDocStat = React.lazy(() => import("../../components/concepts/dossier/ConceptDocStat"))
const ConnectionsTopLinks = React.lazy(() => import("../../components/concepts/connections/ConnectionsTopLinks"))
const ConnectionsPlatforms = React.lazy(() => import("../../components/concepts/connections/ConnectionsPlatforms"))

const ConnectionsDetail = () => {
	const user = useSelector(state => state.user.value)
	const {conceptsID, connectionsType} = useParams()
	const [conceptsMeta, setConceptsMeta] = useState(false)
	const [materialCheckboxes, setMaterialCheckboxes] = useState({});
	const [links, setLinks] = useState(false)
	const [filteredLinks, setFilteredLinks] = useState([])
	const [reportName, setReportName] = useState(false)
	const [checkboxes, setCheckboxes] = useState({})
	const [graphComment, setGraphComment] = useState('')
	const [fileCreating, setFileCreating] = useState(false)
	const [reportDownloadLink, setReportDownloadLink] = useState(false)
	const [modalOpened, setModalOpened] = useState(false);
	const [types, setTypes] = useState(false)
	const [selectedTypes, setSelectedTypes] = useState(false)
	const pathName = useLocation()
	const dispatch = useDispatch()

	useEffect(() => {
		if(conceptsMeta && conceptsMeta !== "fail") {
			const names = (Array.isArray(conceptsMeta) ? conceptsMeta.map(concept => concept.name) : [])
			document.title = 'ИСП РАН аналитика: связи ' + names.join(', ')

			analyticsBACK.post('/history', {
				userID: user.id,
				link: pathName.pathname,
				title: 'Связи объектов ' + names.join(', ')
			})
				.then(resp => {
					if(resp.data)
						dispatch(setHistory(resp.data))
				})
				.catch(resp => {
					console.error('Ошибка добавления в историю', resp)
				})
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [conceptsMeta])

	useEffect( () => {
		if(conceptsID.split(',').length > 0){
			APIClient.query({
				query: CONCEPT_LIST_META,
				variables: {
					ids: conceptsID.split(','),
					linksType: null
				}
			})
				.then(resp => {
					if(Array.isArray(resp.data.listConceptById)){
						let name = []
						const tmpLinks = {}
						const conceptLinkTypes = {}
						setConceptsMeta(resp.data.listConceptById)
						resp.data.listConceptById.forEach(concept => {
							if(concept.paginationConceptLink.total > 0) {
								concept.paginationConceptLink.listConceptLink.forEach(link => {
									const linkType = link.conceptLinkType
									conceptLinkTypes[linkType.id] = linkType
								})
								name.push(concept.name)
								tmpLinks[concept.id] = concept.paginationConceptLink.listConceptLink
							}
						})
						if(Object.keys(conceptLinkTypes).length > 0)
							setTypes(conceptLinkTypes)
						else
							setTypes('none')

						setReportName('Связи объектов: ' + name.join(', '))
						if(Object.keys(tmpLinks).length > 0){
							setLinks(tmpLinks)
							setFilteredLinks(tmpLinks)
						}
						else
							setLinks("fail")

					}
				})
				.catch(resp => {
					console.error(resp)
					setConceptsMeta("fail")
				})
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [conceptsID] )

	useEffect(() => {
		if(selectedTypes && Object.keys(selectedTypes).length > 0){
			console.log('filtring')
			const tmp = {}
			for (const [key, value] of Object.entries(links)) {
				tmp[key] = []
				value.forEach(link => {
					if( Object.keys(selectedTypes).includes(link.conceptLinkType.id)){
						tmp[key].push(link)
					}
				})
			}
			setFilteredLinks(tmp)
		}else
			setFilteredLinks(links)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedTypes])

	const handleSetTypes = (type) => {
		if(selectedTypes[type.id]) {
			const tmp = selectedTypes
			delete tmp[type.id]
			setSelectedTypes({...tmp})
		}else{
			const tmp = {}
			tmp[type.id] = type
			setSelectedTypes({...selectedTypes, ...tmp})
		}
	}

	const makeConnectionsTypes = () => {
		if(types && Object.keys(types).length > 0)
			return Object.values(types).map(type => {
				return <li key={type.id} onClick={() => handleSetTypes(type)} className={(selectedTypes[type.id])? 'active' : ''}>
					<div className="remember-list__itm" >{type.name}</div>
				</li>
			})
	}

	const makeReport = async () => {
		setFileCreating(true)
		setModalOpened(false)

		const fillSection = async ({sectionContent, container}) => {
			return new Promise((resolve, reject) => {
				if(sectionContent){
					container.push(sectionContent)
					resolve(container)
				}else{
					resolve(container)
				}
			})
		}
		fillSection({sectionContent: {type: 'html', content: `<h1>${reportName}</h1>`}, container: []})
			.then(sections => {//get meta
				const conceptsIDArray = conceptsID.split(',')
				let htmlContent = ''
				conceptsIDArray.forEach(conseptID => {
					const isConceptMeta = (document.querySelector(`input[name="conceptMeta_${conseptID}"]`))? document.querySelector(`input[name="conceptMeta_${conseptID}"]`).checked : false
					if(isConceptMeta) {
						let conceptMetaData = document.querySelector(`#conceptMeta_${conseptID}`)
						conceptMetaData.querySelectorAll('.avatar, .remove-dossier-concept, .add-dossier-concept').forEach(obj => {
							obj.remove()
						})
						htmlContent += conceptMetaData.innerHTML + '<br/><br/>'
					}
				})
				return fillSection({sectionContent: {type: 'html', content: '<div>'+htmlContent+'</div>'}, container: sections})
			})
			.then(async sections => {//getting connections graph
				const isConnectionsGraph = (document.querySelector('input[name="ConnectionsGraph"]'))? document.querySelector('input[name="ConnectionsGraph"]').checked : false
				if(isConnectionsGraph){//SankeyGraph
					const svg = document.querySelector("#SankeyGraph svg")
					const conceptLinksPNG = await convertSVGtoImg({svg: svg})
					return fillSection({
						sectionContent: {
							type: "image",
							content: conceptLinksPNG,
							title: 'Граф связей объектов',
							name: 'connections_graph' + user.id + '.png'
						},
						container: sections
					})
				}else
					return fillSection({sectionContent: false, container: sections})
			})
			.then(sections => {//graph comment
				const isConnectionsGraphComment = (document.querySelector('input[name="connectionsGraphComment"]'))? document.querySelector('input[name="connectionsGraphComment"]').checked : false
				if(isConnectionsGraphComment){
					if(graphComment){
						return fillSection({sectionContent: {type: "text", content: graphComment}, container: sections})
					}
				}
				return fillSection({sectionContent: false, container: sections})
			})
			.then(sections => {//docs
				const isConceptDocs = (document.querySelector('input[name="ConceptDocs"]'))? document.querySelector('input[name="ConceptDocs"]').checked : false
				if(isConceptDocs) {
					let docsContent = document.createElement('DIV')
					let docsContentParent = document.querySelector('#ConceptDocs')
					let docsHTML = ''
					docsContent.innerHTML = docsContentParent.innerHTML
					docsContent.querySelectorAll('.tooltip, .tabs-head, .detail-material-itm-content__top a').forEach(obj => {
						obj.remove()
					})
					docsContent.querySelectorAll('.detail-material-itm-content__info__date').forEach(obj => {//parse date
						if(obj.innerText.length > 0)
							obj.innerHTML = obj.querySelector('span:first-child').innerText + ' ' + obj.querySelector('span:last-child').innerText
					})

					if (docsContentParent.querySelector('.tabs-head__itm.active').innerText === 'Только заголовок') {
						docsContent.querySelectorAll('.detail-material-itm-content__text').forEach(obj => {
							obj.remove()
						})
					} else if (docsContentParent.querySelector('.tabs-head__itm.active').innerText === 'Заголовок + анонс') {
						docsContent.querySelectorAll('.detail-material-itm-content__text').forEach(obj => {
							obj.querySelector('.detail-material-itm-content__text__inner').innerText = (obj.innerText.length > 300) ? obj.innerText.substring(0, 300) + '...' : obj.innerText;
						})
					}

					docsHTML += docsContent.querySelector('.title-flex').innerHTML
					docsContent.querySelectorAll('.detail-material-itm').forEach(obj => {
						if (obj.querySelector('input[type="checkbox"]').checked) {
							docsHTML += obj.querySelector('.detail-material-itm-content').outerHTML
						}
					})
					return fillSection({sectionContent: {type: 'html', content: docsHTML}, container: sections})
				}
				return fillSection({sectionContent: false, container: sections})
			})
			.then(async sections => {//doc stats
				const isDocsStat = (document.querySelector('input[name="ConnectionsStat"]'))?  document.querySelector('input[name="ConnectionsStat"]').checked : false
				if(isDocsStat){
					const fetchDocStat = async (conseptID) => {
						return new Promise((resolve) =>{
							let docsStatContent = document.querySelector(`#stat_${conseptID}`)
							let docsStat = document.createElement('DIV')
							docsStat.innerHTML = docsStatContent.innerHTML
							docsStat.querySelectorAll('.detail-references__right').forEach(obj => {
								obj.remove()
							})
							let docsStatSVG = document.querySelector(`#stat_${conseptID} .apexcharts-canvas svg`)
							convertSVGtoImg({svg: docsStatSVG}).then(resp => {
								resolve([
									{type: 'html', content: docsStat.outerHTML},
									{type: 'image', content: resp, name: 'docs_stat_'+ conseptID + user.id + '.png'}
								])
							})
						})
					}
					const conceptsIDArray = conceptsID.split(',')
					let resp = []
					for (const conseptID of conceptsIDArray) {
						resp = await fetchDocStat(conseptID)
						sections.push(resp[0])
						sections.push(resp[1])
					}
					return fillSection({sectionContent: false, container: sections})
				}
				return fillSection({sectionContent: false, container: sections})
			})
			.then(sections => {//top objects
				const isConnectionsDocuments = (document.querySelector('input[name="ConnectionsDocuments"]'))?  document.querySelector('input[name="ConnectionsDocuments"]').checked : false
				if(isConnectionsDocuments){
					let connetionsContent = document.createElement('DIV')
					connetionsContent.innerHTML = document.querySelector('#ConnectionsDocuments').innerHTML
					connetionsContent.querySelectorAll('.tooltip, .item-with-menu__btn').forEach(obj => {
						obj.remove()
					})
					return fillSection({sectionContent: {type: 'html', content: connetionsContent.outerHTML}, container: sections})
				}
				return fillSection({sectionContent: false, container: sections})
			})
			.then(async sections => {//sources
				const isSources = (document.querySelector('input[name="ConnectionsPlatforms"]'))?  document.querySelector('input[name="ConnectionsPlatforms"]').checked : false
				if(isSources){
					const svg = document.querySelector('#ConnectionsSourcesTreemap .apexcharts-canvas svg')
					let conceptLinksPNG
					if(svg){
						conceptLinksPNG = await convertSVGtoImg({svg: svg})
						if(conceptLinksPNG.length > 10)
							sections.push({type: "image", content: conceptLinksPNG, title: 'Перечень источников',  name: 'connections_sources' + user.id + '.png'})
					}
					const text = document.querySelector('#ConnectionsSourcesTable')
					if(text){
						if(conceptLinksPNG.length > 10)
							sections.push({type: 'html', content: text.outerHTML})
						else
							sections.push({type: 'html', content: text.outerHTML,  title: 'Перечень источников'})
					}
				}
				return fillSection({sectionContent: false, container: sections})
			})
			.then(sections => {
				analyticsBACK.post('/reports',
					{
						sections: sections,
						concept: conceptsID,
						conceptName: reportName,
						report_type: 'connections',
						user_id: user.id,
						link: pathName.pathname
					},
				)
					.then(resp => {
						if (resp.data.file) {
							setModalOpened(true)
							setReportDownloadLink(resp.data.file)
							// fileDownload(resp.data, `report${new Date().toLocaleDateString('ru')}.docx`);
						} else {
							console.error('bad request')
						}
						setFileCreating(false)
						setModalOpened(true)
					})
					.catch(() => {
						setFileCreating('fail')
					})
			})
		return true
	}

	return <main className="main-content">
		<div className="content visible">
			<div className="query-detail-page">

				<h1 className="title-big c-main-dark">Связи объектов:</h1>

				<div className="smart-search-result--multi smart-search-result--no-del connection-head-itms">

					{conceptsMeta
						? conceptsMeta.map(concept => {
							return <div key={`top_${concept.id}`} className="smart-search-multiple-result-itm">
								<div className="title c-main-dark">{concept.name}</div>
							</div>
						})
						: <><Skeleton width="200px" height="62px" inline={true} /><Skeleton width="200px" height="62px" inline={true} /></>
					}

				</div>

				{conceptsMeta
					? conceptsMeta.map(concept => {
						const conceptMeta = {
							id: concept.id,
							name: concept.name,
							props: concept.paginationConceptProperty.listConceptProperty,
							metric: concept.metric,
							image: concept.image
						}
						return <ConceptMeta
							key={`cMeta_${concept.id}`}
							multiple={true}
							checkboxes={checkboxes}
							setCheckboxes={setCheckboxes}
							conceptMeta={conceptMeta}
						/>
					})
					: <ConceptMeta
						multiple={true}
						checkboxes={checkboxes}
						setCheckboxes={setCheckboxes}
						conceptMeta={false}
					/>
				}

				{types !== false
					? types !== "none"
						?<RememberList title="Типы связей:">
							<ul>
								{makeConnectionsTypes()}
							</ul>
						</RememberList>
						: ''
					:  <><Skeleton width="100px" height='43px' inline={true} style={{marginRight: '20px', marginBottom: '20px'}} /><Skeleton width="150px" height='43px' /></>
				}

				<ConnectionsGraph  checkboxes={checkboxes} setCheckboxes={setCheckboxes} links={filteredLinks} />

				<QueryDetailLine name="connectionsGraphComment" checkboxes={checkboxes} setCheckboxes={setCheckboxes}>
					<div className="nice-form">
						<div className="title c-main-dark mb-30">Комментарий к графу связей</div>
						<textarea onChange={e => setGraphComment(e.target.value)} value={graphComment} name="" id="" rows="6" placeholder="Введите комментарий"></textarea>
					</div>
				</QueryDetailLine>

				<ConceptDocs
					defVars={ {limit: 20, offset: 0, concepts: conceptsID.split(',')} }
					query={ STORIES_BY_CONCEPTS_ALL_PLATFORMS }
					datesQuery={ STORIES_BY_CONCEPTS_ALL_PLATFORMS_DATES }
					title='Совместные упоминания объектов'
					checkboxes={checkboxes}
					setCheckboxes={setCheckboxes}
					materialCheckboxes={materialCheckboxes}
					setMaterialCheckboxes={setMaterialCheckboxes}
				/>

				<QueryDetailLine name="ConnectionsStat" checkboxes={checkboxes} setCheckboxes={setCheckboxes}>
					<div className="cols cols-2 p30">
						{conceptsMeta
							? conceptsMeta.map(concept => <ConceptDocStat key={`dStat_${concept.id}`} multiple={true} conceptID={concept.id} checkboxes={checkboxes} setCheckboxes={setCheckboxes} conceptMeta={concept} />)
							: <div className="col">
								<div className="title c-main-dark">Количество и частота упоминаний</div>
								<Skeleton height="200px" />
							</div>
						}
					</div>
				</QueryDetailLine>

				<QueryDetailLine name="ConnectionsDocuments" checkboxes={checkboxes} setCheckboxes={setCheckboxes}>
					<div id="ConnectionsDocuments">
						<h3 className="title c-main-dark">Топ связанных объектов
							<Tooltip>Объекты состоящие в связи</Tooltip>
						</h3>
						{conceptsMeta
							? conceptsMeta.map(concept => <ConnectionsTopLinks key={`topLinks_${concept.id}`} concept={concept} />)
							: <div>
								<p><Skeleton width="300px" style={{margin: '10px 0'}}/></p>
								<p><Skeleton width="250px" style={{margin: '10px 0'}}/></p>
								<p><Skeleton width="350px" style={{margin: '10px 0'}}/></p>
								<p><Skeleton width="370px" style={{margin: '10px 0'}}/></p>
							</div>
						}
					</div>
				</QueryDetailLine>

				<ConnectionsPlatforms concepts={conceptsID} checkboxes={checkboxes} setCheckboxes={setCheckboxes} />

				<div className="detail-export">
					<div aria-disabled={(fileCreating)} className="btn detail-export__btn" onClick={() =>{
						if(!reportDownloadLink)
							makeReport()
						else
							setModalOpened(true)
					}}>
						<HandySvg src={ico_doc} width={28} height={34}></HandySvg>
						Экспорт отчета в формате .docx
					</div>
					{fileCreating === 'fail' &&
						<p className='ta-c' style={{fontSize: "1.5rem"}}>Ошибка при создании отчета</p>
					}
					<Modal className="modal--export" opened={modalOpened} setOpened={setModalOpened}>
						<div className="modal-export-top">
							{reportDownloadLink &&
								<a className="btn" href={`${BACK_HOST}/public/${reportDownloadLink}`}  download={true}>
									<HandySvg src={ico_doc} width={28} height={34}></HandySvg>
									Скачать файл отчета
								</a>
							}
						</div>
						{/*<div className="modal-export-bot">*/}
						{/*	<div className="title ta-c c-main-dark">Отправить на email</div>*/}
						{/*	<form className="modal-export-form nice-form">*/}
						{/*		<label className="input-wrap">*/}
						{/*			<input type="text" name="login" placeholder=" "/>*/}
						{/*			<span className="input-wrap__label">Введите email</span>*/}
						{/*		</label>*/}
						{/*		<button className="btn small">Отправить</button>*/}
						{/*	</form>*/}
						{/*</div>*/}
					</Modal>
				</div>

			</div>
		</div>
	</main>
}

export default ConnectionsDetail