import {STEAM_CHART_BAR} from "../../chartsOptions/Steam";
import Chart from "react-apexcharts";
import React, {useEffect, useRef, useState} from "react";
import {HandySvg} from "handy-svg";
import {analyticsBACK, APIClient} from "../../ApiEndpoints";
import Skeleton from "react-loading-skeleton";
import {getDay, getMonthShortNameFromUTC} from "../../functions/datesHelper";

import {
	STORIES_BY_CONCEPTS_ALL_PLATFORMS,
	STORIES_BY_CONCEPTS_AND_PLATFORMS,
	STORIES_BY_QUERY_AND_PLATFORMS,
	STREAM_STORIES_STATISTIC_ALL,
	STREAM_STORIES_STATISTIC_BY_PLATFORMS
} from "../../graphql/streamQueries";
import ico_arrow from "../../ico/arrow.svg";
import {useDispatch, useSelector} from "react-redux";
import {useLocation, useSearchParams} from "react-router-dom";
import {setStreamList} from "../../reducers/streamsSlice";

const StreamDetailSource = ({sourcesArray, streamComment, docsTotal, streamName, docsFacet, qString}) => {
	const user = useSelector(state => state.user.value)
	const block_table = useRef();
	const [fetchingStat, setFetchingStat] = useState(true)
	const [chartData, setChartData] = useState([]);
	const [docsSources, setDocsSources] = useState(false)
	const [chartOptions, setChartOptions] = useState({...STEAM_CHART_BAR})
	const query = useLocation().search
	const [search, setSearch] = useSearchParams();
	const dispatch = useDispatch()
	const [processing, setProcessing] = useState('')
	const [statError, setSataError] = useState(false)

	//gettingStat
	useEffect(() => {
		let date = new Date()
		const periods = {
			date0: Math.ceil(date.getTime()/1000),//from now
			date1: Math.ceil(getDay(date, -30)/1000),//-30d ...
			date2: Math.ceil(getDay(date, -30)/1000),
			date3: Math.ceil(getDay(date, -30)/1000),
			date4: Math.ceil(getDay(date, -30)/1000),
			date5: Math.ceil(getDay(date, -30)/1000),
			date6: Math.ceil(getDay(date, -30)/1000),
			date7: Math.ceil(getDay(date, -30)/1000),
			date8: Math.ceil(getDay(date, -30)/1000),
			date9: Math.ceil(getDay(date, -30)/1000),
			date10: Math.ceil(getDay(date, -30)/1000),
			date11: Math.ceil(getDay(date, -30)/1000),
			date12: Math.ceil(getDay(date, -30)/1000)
		}
		setChartOptions({
			...chartOptions,
			colors: ['#FF9500'],
			xaxis: {
				categories:
					[
						getMonthShortNameFromUTC(periods.date12*1000),
						getMonthShortNameFromUTC(periods.date11*1000),
						getMonthShortNameFromUTC(periods.date10*1000),
						getMonthShortNameFromUTC(periods.date9*1000),
						getMonthShortNameFromUTC(periods.date8*1000),
						getMonthShortNameFromUTC(periods.date7*1000),
						getMonthShortNameFromUTC(periods.date6*1000),
						getMonthShortNameFromUTC(periods.date5*1000),
						getMonthShortNameFromUTC(periods.date4*1000),
						getMonthShortNameFromUTC(periods.date3*1000),
						getMonthShortNameFromUTC(periods.date2*1000),
						getMonthShortNameFromUTC(periods.date1*1000)
					]
			}
		})

		const addVars = (sourcesArray[0] === 'all')? {platforms: null} : {platforms: sourcesArray}
		setFetchingStat(true)
		setSataError(false)

		APIClient.query({
			query: STREAM_STORIES_STATISTIC_BY_PLATFORMS,
			variables: {
				...addVars,
				q: qString,
				...periods,
			}
		})
			.then(resp => {
				if(resp.data.DocsDate_0){
					let count = 12
					let newData = []
					for (let i = count-1; i >= 0; i--){
						newData.push(resp.data["DocsDate_" + i].total)
					}
					setChartData([ {data: newData } ])
				}
			})
			.catch(resp => {
				console.error("STAT_FETCHING_FAIL",resp)
				setSataError(true)
			})
			.finally(() => {
				setFetchingStat(false)
			})
	},[qString])

	const SourceItm = ({item}) => {
		const clickRemove = (item) => {
			let tmp = docsSources
			delete tmp[item.id]
			setDocsSources(tmp)
		}

		return (
			<div className="list-item orange">
				<div className="list-item__name">{item.value.name}</div>
				{/*<div className="list-item__remove" onClick={() => clickRemove(item)}>*/}
				{/*	<div className="cross"></div>*/}
				{/*</div>*/}
			</div>
		)
	}

	const renderSkeleton = () => {
		return <>
			<tr>
				<td><Skeleton width='100px' height='40px' baseColor='#FFE8C9' /></td>
				<td><Skeleton width='30px' /></td>
				<td><Skeleton width='20px' /></td>
			</tr>
			<tr>
				<td><Skeleton width='130px' height='40px' baseColor='#FFE8C9' /></td>
				<td><Skeleton width='25px' /></td>
				<td><Skeleton width='20px' /></td>
			</tr>
			<tr>
				<td><Skeleton width='120px' height='40px' baseColor='#FFE8C9' /></td>
				<td><Skeleton width='30px' /></td>
				<td><Skeleton width='25px' /></td>
			</tr>
		</>
	}

	const getBaseQuery = () => {
		let q = []
		if(search.get('concepts'))
			q.push('concepts=' + search.get('concepts'))
		if(search.get('sources'))
			q.push('sources=' + search.get('sources'))
		if(search.get('q'))
			q.push('q=' + search.get('q'))

		return q
	}

	const saveStream = () => {
		//userID query name concepts platforms
		setProcessing(true)
		analyticsBACK.post('/stream', {
			userID: user.id,
			query: query,
			concepts: 'из запроса',
			platforms: sourcesArray.join(','),
			docs_count: docsTotal,
			name: streamName,
			comment: streamComment
		})
			.then(resp => {
				dispatch(setStreamList(resp.data))
				if(!search.get("from_saved")){
					search.append('from_saved', resp.data[0].id)
					let q = getBaseQuery()
					window.history.replaceState(null, "", `/stream/content?${q.join('&')}&from_saved=${resp.data[0].id}`)
				}
				setProcessing(false)
			})
			.catch(resp => {
				console.error(resp)
				setProcessing(false)
			})
	}

	const removeStream = () => {
		if(search.get('from_saved')){
			setProcessing(true)
			analyticsBACK.delete('/stream', {
				params: {
					streamID: search.get('from_saved'),
					userID: user.id
				}
			})
				.then(resp => {
					if(resp.data){
						dispatch(setStreamList(resp.data))
						search.delete("from_saved")
						let q = getBaseQuery()
						window.history.replaceState(null, "New Page Title", `/stream/content?${q.join('&')}`)
					}

					setProcessing(false)
				})
				.catch(resp => {
					console.error(resp)
					setProcessing(false)
				})
		}
	}

	const printChart = () => {
		if (statError)
			return <span>Ошибка при получении данных, попробуйте повторить попытку позже</span>


		if (fetchingStat)
			return <>
				<Skeleton height="250px" />
				<span className="delayed_message">Загрузка этого модуля может занять больше времени, чем обычно</span>
			</>
		else
			return <Chart
				options={chartOptions}
				series={chartData}
				type="bar"
				width="100%"
				height="250px"
			/>
	}

	return <div className="stream-detail-source">
		<div className="stream-detail-source__cols">
			<div className="col">
				<table ref={block_table} className="stream-detail-source-table">
					<thead>
					<tr key={'headline'}>
						<th>
							<div className="title c-main-dark">Источники:</div>
						</th>
						<th>Страна</th>
						<th>Кол-во<br/> материалов</th>
					</tr>
					</thead>
					<tbody>
					{docsFacet
						?docsFacet.length > 0
							?docsFacet.map(source => {
								return (
									<tr key={source.value.id}>
										<td><SourceItm item={source} objKey={source.value.name}/></td>
										<td>{(source.value.country)? source.value.country : 'нет'}</td>
										<td>{(source.count)? source.count : 0}</td>
									</tr>
								)
							})
							:<tr><td>Нет данных</td><td></td><td></td></tr>
						:renderSkeleton()
					}
					</tbody>
				</table>

				<div className="more-btn orange" onClick={(e)=>{
					block_table.current.classList.toggle('show-all');
					e.target.classList.toggle('show-all');
				}}>
					<HandySvg src={ico_arrow} width={20} height={18} className="rotate"></HandySvg>
					Все выбранные источники
				</div>

			</div>
			<div className="col">
				<p className="stream-detail-source__th">Количество материалов<br/> за один год </p>

				{printChart()}

			</div>
		</div>

		<div className="stream-detail-source__btns">
			{/*<div onClick={() => {changeQuery()}} className="btn">Обновить</div>*/}
			{processing
				? <Skeleton width="200px" height="78px" />
				: search.get('from_saved')
					?<div className="btn" onClick={() => {removeStream()}}>Удалить</div>
					:<div className="btn" onClick={() => {saveStream()}}>Сохранить как постоянную</div>
			}
		</div>

	</div>
}
export default StreamDetailSource