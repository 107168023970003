import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {analyticsBACK} from "../../ApiEndpoints";
import {setSavedConcepts} from "../../reducers/userSlice";
import Skeleton from "react-loading-skeleton";

const Remembered = () => {
	const savedConcepts = useSelector(state => state.user.savedConcepts)
	const user = useSelector(state => state.user.value)
	const dispatch = useDispatch()
	const [rememberedRemoving, setRememberedRemoving] = useState('')

	useEffect(() => {
		document.title = 'ИСП РАН аналитика: сохраненные объекты'
	}, [])

	const removeConcept = (concept) => {
		setRememberedRemoving(concept.id)
		analyticsBACK.delete('/concept/saved', {
			data: {
				userID: user.id,
				conceptID: concept.concept_id
			}
		})
			.then(resp => {
				dispatch(setSavedConcepts(resp.data))
				setRememberedRemoving('')
			})
			.catch(resp => {
				console.error(resp)
				setRememberedRemoving('')
			})
	}

	const makeRememberedList = () => {
		if(Array.isArray(savedConcepts)) {
			if(savedConcepts.length === 0)
				return <div><h3>Нет избранных концептов</h3></div>

			return savedConcepts.map(concept => {
				return <div key={concept.id} className="remember-block">
					<div className="remember-block__itm">
						{rememberedRemoving === concept.id
							? <Skeleton width="300px"  height="43px" />
							:<div className="remember-list-itm">
								<div className="remember-list__itm">{concept.name}</div>
								<div className="remember-list__remove">
									<div onClick={() => removeConcept(concept)} className="cross"></div>
								</div>
							</div>
						}
					</div>
					<div className="remember-block__link">
						<Link to={`/concept/${concept.concept_id}/dossier`}>Сформировать досье</Link>
					</div>
					<div className="remember-block__link">
						<Link to="/connections">Найти связи объекта</Link>
					</div>
					<div className="remember-block__link">
						<Link to={`/stream?concept=${concept.concept_id}`}>Сформировать ленту</Link>
					</div>
				</div>
			})
		}
		return <></>
	}

	return <main className={'main-content'}>
		<div className="content visible">
			<h1 className="title-big main-margin c-main-dark">Запомненные объекты</h1>
			{makeRememberedList()}
		</div>
	</main>
}

export default Remembered;