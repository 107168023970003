import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import Skeleton from "react-loading-skeleton";
import {analyticsBACK} from "../../ApiEndpoints";
import {setStreamList} from "../../reducers/streamsSlice";

const PageAllStreams = () => {
	const streams = useSelector(state => state.stream.list)
	const user = useSelector(state => state.user.value)
	const [deleting, setDeleting] = useState('')
	const dispatch = useDispatch()

	useEffect(() => {
		document.title = 'ИСП РАН аналитика: все ленты'
	}, [])

	const tableHeader = () => {
		const header = [
			"Название ленты",
			"Дата\nсоздания",
			"Дата\nобновления",
			"Количество\nматериалов",
			"Удалить\nленту"
		]
		return <tr key='tableHead'>
			{header.map(itm => <th key={itm}>{itm}</th>)}
		</tr>
	}

	const removeStream = (stream) => {
		if(typeof stream === 'object'){
			setDeleting(stream.id)
			analyticsBACK.delete('/stream', {
				params: {
					streamID: stream.id,
					userID: user.id
				}
			})
				.then(resp => {
					if(resp.data)
						dispatch(setStreamList(resp.data))

					setDeleting('')
				})
				.catch(resp => {
					console.error(resp)
					setDeleting('')
				})
		}
	}

	const tableContent = () => {
		if(Array.isArray(streams)){
			return streams.map(stream => {
				let update = new Date(stream.updated_at)
				let create = new Date(stream.created_at)
				return <tr key={`row_${stream.id}`}>
					<td>
						<Link key={stream.id} to={`/stream/content${stream.query}&from_saved=${stream.id}`} className="title c-main-dark">{stream.name}</Link>
						{stream.updated
							? <span className="badge info">новое</span>
							: ''
						}
					</td>
					<td>
						{create.toLocaleDateString('ru')}<br />
						<span className='c-gray-bg'>{create.toLocaleTimeString('ru')}</span>
					</td>
					<td>
						{update.toLocaleDateString('ru')}<br />
						<span className='c-gray-bg'>{update.toLocaleTimeString('ru')}</span>
					</td>
					<td>
						{stream.documents_count}<br />
						{stream.updated
							? <span className="c-red">{stream.new_count - stream.documents_count}</span>
							: ''
						}
					</td>
					<td>
						{deleting === stream.id
							? <Skeleton highlightColor="#F00" width="15px" height='15px' circle={true} />
							: <div onClick={() => removeStream(stream)} className='cross cross--text'> Удалить</div>
						}
					</td>
				</tr>
			})
		}
	}

	const makeSkeleton = () => {
		return <tr key={`sk_1`}>
			<td>
				<p className='title c-main-dark'><Skeleton key={`fr_1`} width="250px" highlightColor='#412779' /></p>
			</td>
			<td>
				<Skeleton width="150px" />
				<span className='c-gray-bg'><Skeleton width="100px"/></span>
			</td>
			<td>
				<Skeleton width="150px" />
				<span className='c-gray-bg'><Skeleton width="100px"/></span>
			</td>
			<td>
				<Skeleton width='60px' />
			</td>
			<td>
			</td>
		</tr>
	}

	return (
		<main className={'main-content'}>
			<div className="content visible">
				<h1 className="title-big main-margin c-main-dark">Мои ленты</h1>

				<table className="stream-table">
					<thead>
						{tableHeader()}
					</thead>
					<tbody>
						{streams === false
							? makeSkeleton()
							: tableContent()
						}
					</tbody>
				</table>

				<div className="ta-c">
					<Link key='to_all' to="/stream" className="btn">Сформировать новую ленту</Link>
				</div>

			</div>
		</main>
	);
}

export default PageAllStreams