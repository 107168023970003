import React, {useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import LoaderPage from "../../components/laders/LoaderPage";
import authLogo from "../../img/auth-logo.svg";
import authText from "../../img/auth-text.svg";
// import AuthForm from "../../components/auth/AuthForm";
import FormErrors from "../../components/ui/FormErrors";
import {HandySvg} from "handy-svg";
import svg_arrow from "../../ico/arrow.svg";
import {analyticsBACK} from "../../ApiEndpoints";

const Invite = () => {
	const {secret} = useParams()
	const navigate = useNavigate();
	const [formBusy, setFormBusy] = useState(false)
	const [errors, setErrors] = useState([])

	const handleRegister = (e) => {
		setErrors([])
		setFormBusy(true)
		e.preventDefault()
		if(e.target.password.value && e.target.passwordRepeat.value && e.target.password.value === e.target.passwordRepeat.value){
			analyticsBACK.post('/register',{
				name: e.target.name.value,
				login: e.target.email.value,
				lastName: e.target.lastname.value,
				email: e.target.email.value,
				password: e.target.password.value,
				invite: secret
			})
				.then(resp => {
					if(resp.data.data && resp.data.data.updateUserPassword && resp.data.data.updateUserPassword === true)
						navigate('/?register=success', {replace: true})

					console.log(resp)
					setFormBusy(false)
				})
				.catch(resp => {
					if(resp.response.data.message)
						setErrors([resp.response.data.message])
					console.error('Create user fail',resp)
					setFormBusy(false)
				})
		}else {
			setErrors(['Пароли не совпадают'])
		}
	}


	return <div className="auth-page">
		<div className="content visible">
			<LoaderPage loading="" />
			<div className="auth-page-content">
				<div className="auth-page-content__left">
					<img src={authLogo} alt="" className="auth-page-logo"/>
				</div>
				<div className="auth-page-content__right">
					<img src={authText} alt="" className="auth-page-text"/>

					<div className="title">Регистрация</div>

					<p className="small light auth-page-desc">Для получения доступа к базе данных и формированию
						аналитических работ заполните все поля регистрационной формы.</p>

					<form action="" aria-disabled={formBusy} className="nice-form js-auth-form" onSubmit={(e) => handleRegister(e)}>
						<div className="cols cols-2 p10" >
							<div className="col">
								<label className="input-wrap">
									<input
										name='name'
										type='text'
										placeholder=" "
										required={true}
									/>
									<span className="input-wrap__label">Имя</span>
								</label>
							</div>

							<div className="col">
								<label className="input-wrap">
									<input
										name='lastname'
										type='text'
										placeholder=" "
									/>
									<span className="input-wrap__label">Фамилия</span>
								</label>
							</div>

							<div className="col">
								<label className="input-wrap">
									<input
										name='email'
										type='email'
										placeholder=" "
										required={true}
									/>
									<span className="input-wrap__label">Email</span>
								</label>
							</div>

							<div className="col"></div>

							<div className="col">
								<label className="input-wrap">
									<input
										name='password'
										type='password'
										placeholder=" "
										required={true}
									/>
									<span className="input-wrap__label">Пароль</span>
								</label>
							</div>

							<div className="col">
								<label className="input-wrap">
									<input
										name='passwordRepeat'
										type='password'
										placeholder=" "
										required={true}
									/>
									<span className="input-wrap__label">Повтор пароля</span>
								</label>
							</div>

						</div>

						<div className="form-message"><FormErrors errors={errors} /></div>

						<button type='submit' className="btn big">
							Регистрация
							<HandySvg src={svg_arrow} width={20} height={18}/>
						</button>
					</form>
				</div>
			</div>
		</div>
	</div>
}

export default Invite